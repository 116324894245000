<template>
    <div>
        <h3>Con los datos indicados, sólo te podemos ofrecer un producto con doble garantía hipotecaria como aval. </h3>
        <p style="margin-top: 20px;">Un aval es una propiedad sin cargas (propia o familiar) que pueda aportarse como garantía de la hipoteca, para poder financiar un % mayor.</p>
    
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="prescoringData.dgh.avalista" row>
                    <v-radio
                        label="Sí, tengo aval"
                        :value="true"
                        color="#5A4FA2"
                        style="margin: 12px;"
                    ></v-radio>
                    <v-radio
                        :label="noAvalLabel"
                        :value="false"
                        color="#5A4FA2"
                        style="margin: 12px;"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <v-form ref="form" @change="setFilled" style="margin-top: 10px;">
            <v-row id="avalista_block" v-if="prescoringData.dgh.avalista === true" class="mb-6" >
                <v-col cols=12 sm=6>
                    <p class="form-title_whitout_subtitle">¿Quién te avala?</p>
                    <v-select 
                        outlined
                        v-model="prescoringData.dgh.avalista_tipo"
                        placeholder="Indica tu avalista"
                        :items="vars.tipo_avalista"
                        item-text="name"
                        item-value="code"
                        dense
                    />
                </v-col>
                <v-col cols=12 sm=6>
                    <p class="form-title_whitout_subtitle">Valor de la vivienda</p>
                    <v-currency-field
                        outlined
                        v-model="prescoringData.dgh.avalista_valor_vivienda"
                        append-icon="mdi-currency-eur"
                        placeholder="Valor aprox. de mercado"
                        hint="Introduce el valor aproximado de mercado de la vivienda"
                        dense
                    ></v-currency-field>
                </v-col>
                <v-col cols=12 sm=6>
                    <p class="form-title_whitout_subtitle">¿Tiene hipoteca la vivienda?</p>
                    <v-select 
                        outlined
                        v-model="prescoringData.dgh.avalista_hipoteca"
                        :items="vars.yes_no_field"
                        item-text="name"
                        item-value="code"
                        dense
                    />
                </v-col>
                <v-col cols=12 sm=6 v-if="prescoringData.dgh.avalista_hipoteca === 1">
                    <p class="form-title_whitout_subtitle">Capital pendiente de la hipoteca</p>
                    <v-currency-field
                        outlined
                        v-model="prescoringData.dgh.avalista_hipoteca_valor"                    
                        :min="0"
                        append-icon="mdi-currency-eur"
                        hint="Si no lo conoces exactamente, introduce un valor aproximado"
                        dense
                    ></v-currency-field>
                </v-col>
            </v-row> 
        </v-form>  

        <p v-if="isAvalistaHipotecaHigh" class="warning-avalista">La hipoteca no puede superar el 30% del valor de la vivienda</p>
        
        <div class="d-flex flex-column mt-10">
            <span v-if="this.isFilledOperation==false" class="filled-message" style="margin-left:56%">*Faltan datos obligatorios</span>
            <div class="actions_container">
                <button class="button-primary_element second-btn" @click="goToPrev" >
                <span class="button-primary_text">Volver</span>
                </button>
                <button class="button-primary_element" @click="goToNext" :disabled="!canContinue">
                <span class="button-primary_text">Continuar</span>
                </button>
            </div>  
        </div>

    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'AvalView',
        data() {
            return {
                isFilled: false,
            };
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState([
                'vars',
                'isFilledOperation',
                'prescoringData',
                'activeTab',
            ]),
            canContinue() {
                if (this.isAvalistaHipotecaHigh) return false;
                if (this.prescoringData.dgh.avalista === true && this.isFilledOperation === true) return true;
                if (this.prescoringData.dgh.avalista === false) return true;
                return false;
            },
            isAvalistaHipotecaHigh() {
                const avalistaHipotecaValor = this.prescoringData.dgh.avalista_hipoteca_valor;
                const avalistaValorVivienda = this.prescoringData.dgh.avalista_valor_vivienda;
                if (avalistaHipotecaValor && avalistaValorVivienda) {
                    return avalistaHipotecaValor > (avalistaValorVivienda * 0.30);
                }
                return false;
            },
            noAvalLabel() {
                const amount = this.prescoringData.precio_compra * 0.10;
                return `No, pero creo que puedo conseguir ${this.$options.filters.currency(amount)}€`;
            }
        },        
        methods: {
            ...mapActions([
            ]),
            ...mapMutations([
                'SET_IS_FILLED',                
                'SET_PRESCORING_DATA',
                'SET_ACTIVE_TAB',
            ]),
            setFilled(){
                this.SET_IS_FILLED(true)
            },
            goToPrev(){
                this.SET_ACTIVE_TAB('InitialView');
            },
            goToNext(){
                if(this.$refs.form.validate()){
                    if (this.prescoringData.dgh.avalista === false) {
                        this.prescoringData.producto_hipotecario = 'myinvestor';
                    } else {
                        this.prescoringData.producto_hipotecario = 'helloteca';
                    }
                    let params = {
                        dgh: {
                            avalista: this.prescoringData.dgh.avalista,
                            avalista_tipo: this.prescoringData.dgh.avalista_tipo,
                            avalista_valor_vivienda: this.prescoringData.dgh.avalista_valor_vivienda,
                            avalista_hipoteca: this.prescoringData.dgh.avalista_hipoteca,
                            avalista_hipoteca_valor: this.prescoringData.dgh.avalista_hipoteca_valor
                        },
                        producto_hipotecario: this.prescoringData.producto_hipotecario
                    };                     
                    this.SET_PRESCORING_DATA(params); 
                    this.SET_ACTIVE_TAB('MotivationView');
                }else {
                    return this.SET_IS_FILLED(false);
                }
            },
        }
    }
</script>

<style>
.v-input--selection-controls .v-radio > .v-label {
    color: #5A4FA2;
}
.warning-avalista{
    text-align: center; 
    color:#de4141; 
    font-size: 20px; 
    border: 2px solid red;
    border-radius: 8px;
    font-weight: 500;
    padding: 12px;
}

</style>