<template>
    <div  class="main-container">
        <div v-if="activeTab !== 'SuccessView' && activeTab !== 'UnsuccessView'">
            <div class="main-title">Calcula tu hipoteca</div>
            <p class="description"> encuentra la mejor oferta</p>
        </div>
        <div class="card-text_container" v-if="activeTab !== null">
            <InitialView v-if= "activeTab === 'InitialView'" />
            <AvalView  v-if= " activeTab ==='AvalView'" />
            <MotivationView  v-if= "activeTab === 'MotivationView'" /> 
            <SuccessView  v-if= "activeTab === 'SuccessView' "/>
            <UnsuccessView  v-if= "activeTab === 'UnsuccessView' "/>
        </div>
    </div>
</template>

<script>
    import InitialView from './main/InitialView.vue'
    import MotivationView from './main/MotivationView.vue'
    import AvalView from './main/AvalView.vue'
    import SuccessView from './main/SuccessView.vue'
    import UnsuccessView from './main/UnsuccessView.vue'
    import { mapState, mapMutations } from "vuex";


    export default {
        name: 'HelloMain',
        components: {
            InitialView,
            MotivationView,
            AvalView,
            SuccessView,
            UnsuccessView
        },
        computed: {
            ...mapState([
                'activeTab',
            ]),
        },
        methods: {
            ...mapMutations([
                'SET_ACTIVE_TAB'
            ])            
        }
    };
</script>

<style>
    /* MAIN */
    .main-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: "Space Grotesk", sans-serif;
        color: #5A4FA2;
        width: 100%;     
        margin: auto;
    }
    .main-title{
        font-size: 36px;
        font-weight: 500;
        background-image: url(../assets/background/underline.png);
        background-repeat: no-repeat;
        background-position: 75% 78%;
        background-size: 90px;
    }  
    .description {
        font-weight: 400;
        font-size: 16px;
        margin-top: 2px;
    }
    .card-text_container {
        flex: 1; 
        background: #FFFFFF;
        box-shadow: 0px 4px 30px 10px rgba(52, 41, 105, 0.1);
        text-align: left;          
        padding: 15px;
        border-radius: 16px;
        min-width: 320px;
    }
    @media (min-width: 600px){
        .main-container {
            max-width: 600px;        
        }
        .main-title{
            font-size: 40px;
            font-weight: 600;
            background-image: url(../assets/background/underline.png);
            background-repeat: no-repeat;
            background-position: 70% 80%;
            background-size: 107px;
        }
        .card-text_container {
            padding: 30px;
        }
    }


    /* FORMULARIO */
    .form-title{
        color:#5A4FA2;
        font-weight: 550;
        font-size: 18px;
    }
    .form-subtitle{
        line-height: 15px; 
        font-size: 15px;
    }
    .form-title_whitout_subtitle{
        color:#5A4FA2;
        font-weight: 550;
        margin-bottom: 8px !important;
    }
    input{
        font-size: 16px;
        font-weight: 400;
    }
    .v-select__slot,.v-text-field__slot {
        padding: 0 14px;
        background: #FFFFFF;
        border: 1px solid #5A4FA2;
        border-radius: 8px;
        width: 100%;
        color:#786EB8;
    }
    .v-select__slot,
    .v-select__slot>input{cursor: pointer;}
    .areatext .v-text-field__slot {
        height:100px;
        margin-right: 0 !important;
        padding: 0;
        border: 0;
    }
    .v-select__selections,
    .v-input textarea{
        color:#786EB8 !important;
        font-weight: 400;
        line-height: 18px;
    }
    .v-input{
        margin-bottom: -25px !important;
    }
    .v-input--is-disabled input{
        color: rgb(171, 171, 171) !important;
    }
    .v-input__append-inner{position: absolute; right: 10px;}
    input{color: #786EB8 !important;}
    
    .v-input__slot {
        margin:0px;
        padding: 0px !important;
        min-height: 45px !important;
        margin-bottom: 3px;
    }
    .v-text-field .v-input__append-outer, 
    .v-text-field .v-input__prepend-outer,
    .v-text-field .v-input__append-inner, 
    .v-text-field .v-input__prepend-inner{
        margin-top: 11px !important;
    }
    .monthly .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-right: 45px;
    }
    .v-input.monthly .v-input__append-inner .v-input__icon{
        width: 100px;
    }
    .v-input.monthly .v-input__append-inner .v-input__icon:after{
        content: "/mes";
        display: block;
        position: absolute;
        right: 0;
        color: #68CAD4;
    }
    .v-text-field--outlined fieldset{
        display: none;
    }
    .v-input__append-inner .v-input__icon.v-input__icon--append i.v-icon{
        color: #68CAD4;
    }
    ::placeholder{
        color: rgb(207, 197, 197);
    }
    .mdi-currency-eur::before {
        content: "\F01AD";
        color: #68CAD4;
    }     
    .icon-input-div-left {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: stretch;
        align-items: stretch;
        align-content: stretch;
    }
    .icon-input-div-left > .input-primary_element{
        padding: 10px 14px;
        border-right: 1px solid transparent;
        border-radius: 8px 0px 0px 8px;
        margin: 0;
        width: 88%;
        color: grey;
    }
    .icon-input-div-left > .icon-left {
        width: 12%;
        margin: 0;
        border-top: 1px solid #8778EB;
        border-bottom: 1px solid #8778EB;
        border-right: 1px solid #8778EB;
        border-radius: 0px 8px 8px 0px;
    }


    /* ESTILOS RADIO BUTTON */
    .options-container {
        min-height: 80px;
        font-weight: 500;
        font-size: 14px;
        color: #9089C1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 18px;
        margin-bottom: 20px;
        border: 2px solid #DDDBED;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 0;
    }
    .radio-button_container{
        cursor:pointer;
        border-bottom: 1px solid #DDDBED;
        padding:2%;
        display:flex;
        flex-direction: row;
        align-items: center;
    }
    .radio-button_container:last-child {
        border-bottom: none;
    }
    .radio-button_container input {
        appearance: none;
        visibility: hidden;
    }
    .radio-button_container label {
        cursor:pointer;
    }
    .circle {
        cursor:pointer;
        width:22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid #9089C1;
        background-color: white;
        margin-right:2%;
    }
    .isChecked{
        cursor:pointer;
        width:12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5A4FA2;
        margin: 4px;
    }


    /* BOTONES */
    .actions_container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }    
    .button-continue {
        width: 148px;
        height: 40px;
        border-radius: 100px;
        color:#FFFFFF;
        font-family: 'Archia','Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        box-shadow: none;
    }
    .button-continue:enabled {
        background: #5A4FA2;
    }
    .button-continue:disabled {
        background: #CDD1DE;
    } 

    /* BOTÓN PRIMARIO PERSONALIZADO */
    .button-primary_element{
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        background: #5A4FA2;
        color: #FFFFFF;
        border: 1px solid #5A4FA2;
        border-radius: 100px;
        padding: 10px 40px;
        min-width: 125px;
    }
    .button-primary_success{
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        background: #5A4FA2;
        color: #FFFFFF;
        border: 1px solid #5A4FA2;
        border-radius: 100px;
        padding: 10px 20px;
        min-width: 125px;
    }
    .button-primary_element:hover, .button-primary_success:hover{
        color: #5A4FA2;
        background: #FFFFFF;
        transition: all .3s;
    }
    .button-primary_element:disabled{
        background: #CDD1DE;
        cursor:not-allowed;
    }
    .button-primary_text{
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    .second-btn {
        background: #FFFFFF;
        color: #5A4FA2;
    }
    .button-secundary_element{
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        background:var(--helloteca-secundary);
        color: #FFFFFF;
        border: 1px solid var(--helloteca-secundary);
        border-radius: 100px;
        padding: 20px 80px;
        min-width: 125px;
        margin-top:15px;
    }
    .button-secundary_element:hover{
        color: #5A4FA2;
        background: #FFFFFF;
        transition: all .3s;
    }
    /* VERSION MOVIL */
    @media (max-width: 600px){
        .button-secundary_element{
            font-size: 16px;
            border-radius: 80px;
            padding: 20px ;
        }
    }
    
</style>